.AIJokeContainer {
	position: relative;
	width:100%;
	height:300px;
	margin:16px 0;
}

.AIJoke{
	width: 100%;
	height: 100%;
	color:#4aaeff;
	background-color:#1f1f1f;
	background:radial-gradient(rgba(0, 0, 150, 0.75), black 120%);
	padding:16px;
	border-radius:4px;
	font:1.3rem Inconsolata, monospace;
	text-shadow:0 0 5px #abd9ff;
	overflow: scroll;
}

.TerminalGrit{
	background: repeating-linear-gradient(0deg,
		rgba(0,0,0, 0.2),
		rgba(0,0,0, 0.2) 1px,
		transparent 1px,
		transparent 2px
	);
	content: "";
	position: absolute;
	bottom: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 100;
}
