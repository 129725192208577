.VeinsCanvas{
	width: 100%;
	height: 200px;
}

.VeinSegment {
	transition: 0.4s;
}

.VeinSegment.Vertical {
	animation: draw 0.25s linear forwards;
	stroke-dasharray: 25;
	stroke-dashoffset: 25;
}

.VeinSegment.Diagonal {
	animation: draw 0.35s linear forwards;
	stroke-dasharray: 36;
	stroke-dashoffset: 36;
}

@keyframes draw {
	to {
		stroke-dashoffset:0;
	}
}