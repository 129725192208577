.ExperienceBlock {
	padding: 16px 32px;
	border: 1px solid #ddd;
	border-radius: 4px;
	box-shadow: 0 0 16px rgba(0,0,0,0.4);
}

.ExperienceBlock  h2 {
	display: flex;
	align-items: center;
	border-bottom: 1px solid #ddd;
	padding-bottom: 8px;
	justify-content: space-between;
	font-size:16px;
}

@media (min-width: 450px){
	.ExperienceBlock  h2 {
		font-size:18px;
	}
}

@media (min-width: 609px){
	.ExperienceBlock  h2 {
		font-size:24px;
	}
}

.ExperienceBlock  h2 img {
	margin-right: 8px;
}

.ExperienceBlock  h2 small {
	color: #3120b3;
	font-size: 16px;
}

.ExperienceBlock  h2 span {
	display: flex;
	align-items: center;
}	

.ExperienceBlock  h3 {
	font-size: 20px;
}

.BadgeList {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	margin-bottom: 24px;
	list-style-type: none;
}

.BadgeList li {
	padding: 2px 8px;
	font-size:12px;
	margin: 1px;
	border: 1px solid #eee;
	border-radius: 4px;
}

@media (min-width: 600px){
	.BadgeList li {
		padding: 4px 16px;
		margin: 2px;
		font-size: 14px;
	}
}