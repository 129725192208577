.StyleTransfers {
	width: 100%;
	margin-bottom: 16px;
}

@media (min-width: 600px){
	.StyleTransfers {
		padding-right: 8px;
		width:44%;
		float:left;
		min-width:300px;
		margin: 8px;
	}
}