.BusinessCard{
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	padding: 40px;
	border:4px solid rgb(63,45,90);
	max-width:90%;
	box-shadow:0 0 24px rgba(0,0,0,0.4);
}

.Heading{
	font-size: 32px;
}

@media (min-width: 500px){
	.BusinessCard{
		padding:60px 80px;
	}

	.Heading{
		font-size: 40px;
	}
}