.Backdrop {
	width: 100vw;
	height: 100vh;
	background-color: rgba(0,0,0,0.4);
	position: absolute;
	top: 0;
	left: 0;
	z-index: 8;
	visibility: hidden;
	opacity: 0;
	transition: 0.2s;
}

.Backdrop.Show{
	visibility: visible;
	opacity: 1;
}