.Jumbo{
	position: relative;
	height: 300px;
}

.Jumbo > * {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	box-shadow: 0 0 16px rgba(0,0,0,0.6);
}

.HeadingShadow, .Heading {
	height: 102px;
	width: 360px;
	max-width: 100%;
}

.HeadingShadow {
	box-shadow: 0 0 16px rgba(0,0,0,0.6);
	background-color: rgba(0,0,0,0.5);
	background: linear-gradient(to bottom, rgba(0,0,0,0),rgba(0,0,0,0.6),rgba(0,0,0,0))
}

.Heading {
	background-color:#fff;
	mix-blend-mode:screen;
	line-height: 80px;
	text-align: center;
	margin:0;
}

.Heading small {
	line-height: inherit;
	display: none;
	font-size:16px;
	color:#ad112e;
}

@media (min-width: 360px) {
	.HeadingShadow, .Heading {
		height: 114px;
	}
	.Heading small {
		display: inline;
	}
}

.WebCols {
	display:flex;
	justify-content:space-between;
	flex-flow: column;
	align-items: stretch;
}

.WebCol {
	width:100%;
	text-align:justify;
	align-items:stretch;
}

.ColDivider {
	height:1px;
	background:#ddd;
	margin:16px 0;
}

@media (min-width: 960px){
	.WebCols {
		flex-flow: row;
	}

	.WebCol {
		width:400px;
	}

	.ColDivider {
		width:1px;
		height: auto;
		margin:none;
	}
}