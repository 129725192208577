.FadeIn{
	opacity: 0;
	transition: 1s;
}

.Introducing {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.AspectBox, .DeepBlue, .Nate, .TheShadowOfNate {
	position: absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	box-shadow: 0 0 16px rgba(0,0,0,0.8);
}

.Bowering {
	width: 100%;
}

.BoweringGradient{
	background:rgb(22,22,22);
}

.DeepBlue {
	width: 740px;
	max-width: 86%;
	background-color: #3120b3;
	height: 200px;
}

.Somewhere {
	width: 100%;
}

.SomewhereGradient{
	background: rgb(70,60,100);
}

.Nate, .TheShadowOfNate {
	height:60px;
	width: 600px;
	max-width: 60%;
	background-color: rgba(0,0,0,0.1);
}

.Nate {
	font-weight:600;
	line-height:60px;
	font-size:30px;
	text-align:center;
	background-color:#fff;
	mix-blend-mode:screen;
}

.Rojas {
	font-size: 12px;
	color: #f00;
}

.Show {
	opacity: 1;
}

@media (min-width: 767px){
	.Nate, .TheShadowOfNate {
		height:120px;
		width: 600px;
		max-width: 60%;
	}
	.Nate {
		line-height:120px;
		font-size:60px;
	}
}
