.AboutContainer {
	width:100%;
	max-width:960px;
	margin:auto;
	position:relative;
	padding: 0 8px 128px 8px;
}

.AboutHeader{
	margin-bottom:40px;
	text-align: center;
	width: 100%;
	position: relative;
	height: 128px;
}

.BlueBand{
	position: absolute;
	background-color: #3120b3;
	z-index: -1;
	box-shadow: 0 0 16px rgba(0,0,0,0.6);
	height: 73px;
	left:0;
	top: 50%;
	width: 100%;
	transform: translateY(-50%);
}

.BioBower {
	position: absolute;
	top:50%;
	left:50%;
	transform: translate(-50%,-50%);
	box-shadow:  0 0 16px rgba(0,0,0,0.6);
	max-width: 100%;
}

.AboutHeading {
	padding: 16px 60px;
	box-shadow:  0 0 16px rgba(0,0,0,0.6);
	position: absolute;
	top:50%;
	left:50%;
	transform: translate(-50%,-50%);
	box-shadow:  0 0 16px rgba(0,0,0,0.6);
	background-color: #fff;
}

.AboutHeading > small {
	font-size: 14px;
	color: #bd3737;
}

.TLDR{
	text-align: center;
	margin:32px auto;
}

.Content {
	display:flex;
	justify-content:space-between;
	align-items: center;
	flex-flow: column;
	margin:  auto;
}

.ContentBlock {
	flex:0 0 auto;
	width:512px;
	max-width: 100%;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
}

.Content p {
	margin-bottom: 16px;
	text-align: justify;
}

.Content p:last-of-type{
	margin-bottom: 0;
}

.Passion {
	color:#3120b3;
	margin-right:4px;
	display:inline-block;
	cursor:pointer;
}

.Passion:hover{
	text-decoration: underline;
}

.PassionContent{
	display: block;
	padding: 16px;
	border-radius: 4px;
	max-width: 512px;
	background-color: #fff;
	box-shadow: 0 0 16px rgba(0,0,0,0.6);
}

.QuoteContainer {
	width: 100%;
	margin:24px 0;
	max-width: 100%;
}

.QuoteContainer > img {
	width: 100%;
}

.Quote {
	font-family: monospace;
	font-size: 18px;
	text-align: justify;
	margin:0;
}

.Me {
	float:left;
	width: 512px;
	transform: scaleX(-1);
	margin-right: 8px;
}

.Divider {
	margin:16px 0;
	background-color:#777;
	width:1px;
	position: relative;
}

.Divider:before,.Divider:after{
	content:'';
	position: absolute;
	width: 9px;
	height: 1px;
	background-color: #777;
	left:-4px;
}

.Divider:before {
	top:-1px;
}

.Divider:after{
	bottom:-1px;
}

.Heading3 {
	margin-bottom: 8px;
}

@media (min-width: 400px){
	.Quote {
		font-size: 24px;
	}
}

@media (min-width: 1000px){
	.Content {
		flex-flow: row;
		align-items: stretch;
	}

	.ContentBlock {
		width: 400px;
		max-width: 100%;
	}

	.Divider {
		margin:0;
	}
}
