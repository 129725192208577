*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

h1,h2 {
  font-family: 'Orbitron', sans-serif;
}

body,html,#root{
	width: 100%;
	height: 100%;
	position: relative;
	overflow: scroll;
}

#Content{
	flex: 1 1 auto;
	position: relative;
	height: 100vh;
	width: 100vw;
	padding-top: 68px;
}