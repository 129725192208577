.Nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	display: flex;
	align-items: stretch;
	z-index: 10;
	font-family: 'Orbitron';
	flex: 0 0 auto;
	background-color: transparent;
	opacity: 0;
	transition: 1s;
}

.Nav.Show{
	opacity: 1;
}

.MenuToggle{
	flex: 0 0 auto;
	width: 40px;
	border:none;
	background-color: transparent;
	position: relative;
	z-index: 10;
}

.ToggleIcon {
	margin:auto;
	display: inline-block;
	position: relative;
}

.ToggleIcon, .ToggleIcon:after, .ToggleIcon:before{
	display: block;
	content:'';
	width: 20px;
	height: 4px;
	border-radius: 2px;
	background-color: #1e1280;
}

.ToggleIcon:after, .ToggleIcon:before {
	position: absolute;
}

.ToggleIcon:before {
	top: -8px;
}

.ToggleIcon:after {
	bottom: -8px;
}

.NavMenu {
	position: absolute;
	top: 0;
	left: 0;
	width: 300px;
	font-size: 18px;
	padding: 40px 16px 16px 16px;
	transform: translateX(-100%);
	transition: 0.2s;
	z-index: 9;
	background-color: #fff;
	max-width: 90vw;
	max-height: 100vh;
	opacity: 0;
	box-shadow: 0 0 16px rgba(0,0,0,0.6);
	overflow-y: scroll;
}

.NavLink {
	display: block;
	width: 100%;
	height: 32px;
	line-height: 32px;
	text-align: center;
	font-size: 14px;
	text-decoration: none;
	color: #111;
	border: none;
	background-color: transparent;
	transition: 0.2s;
	position: relative;
}

.NavLink:before{
	content:'';
	position: absolute;
	width: 8px;
	border-radius: 4px;
	background-color: transparent;
	height: 8px;
	left: -8px;
	top: 50%;
	transform: translateY(-50%);
	transition: 0.2s;
}

.NavLink:hover:before{
	background-color: #3120b3;
}

.NavMenu.Open{
	opacity: 1;
	transform: translateX(0);
}

@media (min-width: 800px){
	.MenuToggle {
		display: none;
	}

	.Nav{
		height: 60px;
	}

	.NavMenu {
		padding: 0 32px;
		opacity: 1;
		transform: translateX(0);
		width: 100%;
		max-width: 100%;
		background-color: transparent;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: space-around;
		box-shadow: none;
		background: linear-gradient(
			to bottom, 
			rgba(255,255,255,1),
			rgba(255,255,255,0)
		)
	}

	.NavMenu:hover{
		background-color: #fff;
		box-shadow: 0 0 16px rgba(0,0,0,0.6);
	}

	.NavLink {
		width: auto;
		padding: 0 16px;
	}
}





